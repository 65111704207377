import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';

export enum ClientType {
  Regular = 'regular',
  Host = 'host',
  Player = 'player',
}

const getAppId = () => sessionStorage.getItem('appId');

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private readonly router = inject(Router);

  get appId() {
    return [`${environment.name}/${environment.version}`, getAppId()].join(' ').trim();
  }

  get isInApp() {
    return !!getAppId() || /Викторина/.test(navigator.userAgent) || /com.kvestiks.quiz/.test(navigator.userAgent);
  }

  get isHost() {
    return this.router.isActive('/host', {
      paths: 'subset',
      matrixParams: 'ignored',
      queryParams: 'ignored',
      fragment: 'ignored'
    });
  }

  get isPlayer() {
    return this.router.isActive('/player', {
      paths: 'subset',
      matrixParams: 'ignored',
      queryParams: 'ignored',
      fragment: 'ignored'
    });
  }

  get clientType(): ClientType {
    if (this.isHost) {
      return ClientType.Host;
    }
    if (this.isPlayer) {
      return ClientType.Player;
    }
    return ClientType.Regular;
  }
}
