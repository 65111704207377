import {appData} from '../../ngsw-config.json';

declare global {
  interface Window {
    environment: string;
    title: string;
    apiUrl: string;
    socketUrl: string;
    sentryUrl: string;
    sentryEnv: string;
    sentrySampleRate: number;
    cdnMirrorUrl: string;
    gtmId: string;
  }
}

export const environment = {
  name: 'game-web',
  version: appData.version,
  production: true,
  environment: window.environment,
  title: window.title,
  apiUrl: window.apiUrl,
  socketUrl: window.socketUrl,
  sentryUrl: window.sentryUrl,
  sentryEnv: window.sentryEnv,
  sentrySampleRate: window.sentrySampleRate,
  cdnMirrorUrl: window.cdnMirrorUrl,
  gtmId: window.gtmId,
};
